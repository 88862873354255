import { createWebHistory, createRouter } from "vue-router";

import Welcome from "@/components/Welcome.vue";
import LoginComponent from "@/components/AuthComponent/LoginPage.vue";
import Register from "@/components/AuthComponent/Register.vue";
import ResetPassword from "@/components/AuthComponent/ResetPassword.vue";
import Choice from "@/components/OwnerComponent/SubProductComponent/Choice.vue";
import Index from "@/components/OwnerComponent/SubProductComponent/Index.vue";
import OwnerSubCategoriesChoice from "@/components/OwnerComponent/SubProductComponent/SubCategories.vue";

// lazy-loaded

// Order Detail
const Order = () => import("@/components/OrderComponent/Order.vue");
const OrderDetail = () => import("@/components/OrderComponent/OrderDetail.vue");

// Resto Component
const Home = () => import("@/components/RestoComponent/Home.vue");
const RestoDetail = () => import("@/components/RestoComponent/RestoDetail.vue");
const RestoFeature = () =>
  import("@/components/RestoComponent/RestoFeature.vue");
const Profile = () => import("@/components/AuthComponent/Profile.vue");
const UpdateProfile = () =>
  import("@/components/AuthComponent/EditProfile.vue");
const Checkout = () => import("@/components/CheckoutComponent/Checkout.vue");
const Transaction = () =>
  import("@/components/TransactionComponent/Transaction.vue");
const Transactions = () =>
  import("@/components/TransactionComponent/Transactions.vue");
const Map = () => import("@/components/MapComponent/Map.vue");
const QR = () => import("@/components/QrComponent/QR.vue");
const QRHome = () => import("@/components/QrComponent/QRHome.vue");
const History = () => import("@/components/HistoryComponent/History.vue");
const HistoryDetail = () =>
  import("@/components/HistoryComponent/HistoryDetail.vue");

//Category Component
const Category = () => import("@/components/CategoryComponent/Category.vue");

//Product Component
const ProductDetail = () =>
  import("@/components/ProductComponent/ProductDetail.vue");

//Request Component
const Request = () =>
  import("@/components/RequestProductComponent/RequestProduct.vue");
const RequestForm = () =>
  import("@/components/RequestProductComponent/RequestForm.vue");
const OfferForm = () =>
  import("@/components/RequestProductComponent/OfferForm.vue");
const Payment = () =>
  import("@/components/RequestProductComponent/Payment.vue");
const ReviewProduct = () =>
  import("@/components/RequestProductComponent/ReviewProduct.vue");
const OtherReviewProduct = () =>
  import("@/components/RequestProductComponent/OtherReviewProduct.vue");
const OfferProduct = () =>
  import("@/components/RequestProductComponent/OfferProduct.vue");

//Owner Resto Component
const SelfProductCategories = () =>
  import("@/components/OwnerComponent/Self/Category.vue");
const ListSelfProduct = () =>
  import("@/components/OwnerComponent/Self/List.vue");
const Automotive = () =>
  import("@/components/OwnerComponent/Self/Product/Automotive.vue");
const Property = () =>
  import("@/components/OwnerComponent/Self/Product/Property.vue");
const Item = () => import("@/components/OwnerComponent/Self/Product/Item.vue");
const OwnerTable = () =>
  import("@/components/OwnerComponent/TableComponent/Table.vue");
const Addition = () =>
  import("@/components/Shop/Management/Create/Addition.vue");
const Image = () => import("@/components/Shop/Management/Create/Image.vue");
const DataOwner = () =>
  import("@/components/Shop/Management/Create/DataOwner.vue");
const DataUser = () =>
  import("@/components/Shop/Management/Create/DataUser.vue");
const OwnerRestoEdit = () =>
  import("@/components/OwnerComponent/RestoComponent/RestoEdit.vue");
const OwnerReferral = () =>
  import("@/components/OwnerComponent/RestoComponent/ReferralCode.vue");
const OwnerVerifikasi = () =>
  import("@/components/OwnerComponent/RestoComponent/RestoVerifikasi.vue");
const OwnerHome = () => import("@/components/OwnerComponent/Home.vue");
const OwnerProduct = () =>
  import("@/components/OwnerComponent/ProductComponent/Product.vue");
const OwnerProductForm = () =>
  import("@/components/OwnerComponent/ProductComponent/ProductForm.vue");
const AttachmentVue = () => import("@/components/Attachment.vue");
const OwnerService = () =>
  import("@/components/OwnerComponent/ServiceComponent/Service.vue");
const OwnerServiceForm = () =>
  import("@/components/OwnerComponent/ServiceComponent/ServiceForm.vue");
const OwnerAddOn = () =>
  import("@/components/OwnerComponent/ProductComponent/AddOn.vue");
const OwnerSubCategories = () =>
  import(
    "@/components/OwnerComponent/SubCategoriesComponent/SubCategories.vue"
  );
const OwnerPromo = () =>
  import("@/components/OwnerComponent/PromoComponent/Promo.vue");
const OwnerPromoForm = () =>
  import("@/components/OwnerComponent/PromoComponent/PromoForm.vue");
const OwnerTransaction = () =>
  import("@/components/OwnerComponent/TransactionComponent/Transaction.vue");
const OwnerDetailTransaction = () =>
  import(
    "@/components/OwnerComponent/TransactionComponent/DetailTransaction.vue"
  );
const Product = () => import("@/components/HomeComponent/Product.vue");
const Service = () => import("@/components/HomeComponent/Service.vue");
const SubCategory = () => import("@/components/HomeComponent/Category.vue");
const FilterProduct = () =>
  import("@/components/HomeComponent/FilterComponent/FilterProduct");
const FilterService = () =>
  import("@/components/HomeComponent/FilterComponent/FilterService");
const OwnerFollower = () =>
  import("@/components/OwnerComponent/FollowerComponent/Follower.vue");
const OwnerVoucher = () =>
  import("@/components/OwnerComponent/VoucherComponent/Voucher.vue");
const OwnerUser = () =>
  import("@/components/OwnerComponent/UserComponent/User.vue");
const OwnerSchedule = () =>
  import("@/components/OwnerComponent/ScheduleComponent/Schedule.vue");
const OwnerSubscription = () =>
  import("@/components/OwnerComponent/SubscriptionComponent/Subscription.vue");
const OwnerHistory = () =>
  import("@/components/OwnerComponent/HistoryComponent/History.vue");
const OwnerOwners = () =>
  import("@/components/OwnerComponent/OwnerComponent/Owner.vue");
const OwnerIncome = () =>
  import("@/components/OwnerComponent/IncomeComponent/Income.vue");
const OwnerRestoDetail = () =>
  import("@/components/OwnerComponent/RestoComponent/Resto.vue");

const OwnerOrderDetail = () =>
  import("@/components/OwnerComponent/OrderComponent/OrderDetail.vue");

const OutletDetail = () =>
  import("@/components/OutletComponent/OutletDetail.vue");
const Favorite = () => import("@/components/HomeComponent/Favorite.vue");

let routes = [
  {
    path: "/",
    component: Welcome,
  },
  {
    path: "/login",
    component: LoginComponent,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/outlet-detail/:outletId",
    name: "OutletDetail",
    component: OutletDetail,
  },
  {
    path: "/request",
    name: "Request",
    component: Request,
  },
  {
    path: "/request-form/:item?",
    name: "RequestForm",
    // lazy-loaded
    component: RequestForm,
  },
  {
    path: "/offer-form/:request_product/:offer_product?",
    name: "OfferForm",
    // lazy-loaded
    component: OfferForm,
  },
  {
    path: "/payment/:from/:id",
    name: "Payment",
    // lazy-loaded
    component: Payment,
  },
  {
    path: "/offer-product/:product",
    name: "OfferProduct",
    // lazy-loaded
    component: OfferProduct,
  },
  {
    path: "/review-product/:item",
    name: "ReviewProduct",
    // lazy-loaded
    component: ReviewProduct,
  },
  {
    path: "/other-review-product/:item",
    name: "OtherReviewProduct",
    // lazy-loaded
    component: OtherReviewProduct,
  },
  {
    path: "/product",
    name: "Product",
    // lazy-loaded
    component: Product,
    props: true,
  },
  {
    path: "/service",
    name: "Service",
    // lazy-loaded
    component: Service,
    props: true,
  },
  {
    path: "/self-product-categories",
    name: SelfProductCategories,
    component: SelfProductCategories,
  },
  {
    path: "/automotive/:id?",
    name: Automotive,
    component: Automotive,
  },
  {
    path: "/property/:id?",
    name: Property,
    component: Property,
  },
  {
    path: "/item/:id?",
    name: Item,
    component: Item,
  },
  {
    path: "/list-self-product/:type",
    name: ListSelfProduct,
    component: ListSelfProduct,
  },
  // {
  //   path: "/self-product/:id/activate",
  //   name: ActivateSelf,
  //   component: ActivateSelf,
  // },
  {
    path: "/sub-category/:category",
    name: "SubCategory",
    // lazy-loaded
    component: SubCategory,
    props: true,
  },
  {
    path: "/filter-service/:id?",
    name: "Filter Service",
    // lazy-loaded
    component: FilterService,
    props: true,
  },
  {
    path: "/filter-product/:id?",
    name: "Filter Product",
    // lazy-loaded
    component: FilterProduct,
    props: true,
  },
  {
    path: "/category/:page",
    name: "Category",
    // lazy-loaded
    component: Category,
    props: true,
  },
  {
    path: "/product-detail/:productId",
    name: "ProductDetail",
    // lazy-loaded
    component: ProductDetail,
    props: true,
  },
  {
    path: "/home",
    name: "Home",
    // lazy-loaded
    component: Home,
  },
  {
    path: "/favorite",
    name: "Favorite",
    component: Favorite,
  },
  {
    path: "/resto-detail/:restoId",
    name: "RestoDetail",
    // lazy-loaded
    component: RestoDetail,
    props: true,
  },
  {
    path: "/resto-feature/:page",
    name: "RestoFeature",
    // lazy-loaded
    component: RestoFeature,
    props: true,
  },
  {
    path: "/checkout",
    name: "Checkout",
    // lazy-loaded
    component: Checkout,
  },
  {
    path: "/transaction/:from?",
    name: "Transaction",
    // lazy-loaded
    component: Transaction,
  },
  {
    path: "/transactions",
    name: "Transactions",
    // lazy-loaded
    component: Transactions,
  },
  {
    path: "/map",
    name: "Map",
    // lazy-loaded
    component: Map,
  },
  {
    path: "/barcode/:detail",
    name: "Qr",
    // lazy-loaded
    component: QR,
  },
  {
    path: "/scan",
    name: "QrScanner",
    // lazy-loaded
    component: QRHome,
  },
  {
    path: "/profile/:item?",
    name: "Profile",
    component: Profile,
    props: true,
  },
  {
    path: "/profile/edit/:item?",
    name: "EditProfile",
    component: UpdateProfile,
    props: true,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/history/:id/:from",
    name: "HistoryDetail",
    component: HistoryDetail,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
  },
  {
    path: "/order-detail/:id",
    name: "OrderDetail",
    component: OrderDetail,
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("@/components/Cart/CartComponent.vue"),
  },
];

// resto owner route
const routesOwner = [
  {
    path: "/owner/order-detail/:id",
    name: "OwnerOrderDetail",
    component: OwnerOrderDetail,
  },
  {
    path: "/data-user/:company_id",
    name: "DataUser",
    component: DataUser,
  },
  {
    path: "/data-owner",
    name: "DataOwner",
    component: DataOwner,
  },
  {
    path: "/addition/:company_id/:outlet_id",
    name: "Addition",
    component: Addition,
  },
  {
    path: "/image/:company_id/:outlet_id",
    name: "Image",
    component: Image,
  },
  {
    path: "/owner/resto-edit",
    name: "OwnerRestoEdit",
    component: OwnerRestoEdit,
  },
  {
    path: "/owner/referral",
    name: "OwnerReferral",
    component: OwnerReferral,
  },
  {
    path: "/owner/verifikasi",
    name: "OwnerVerifikasi",
    component: OwnerVerifikasi,
  },
  {
    path: "/owner/home",
    name: "OwnerHome",
    component: OwnerHome,
  },
  {
    path: "/owner/:category?/:itemId?/choice/:id?",
    name: "ProductChoice",
    component: Choice,
  },
  {
    path: "/owner/choice-list/:category?/:itemId?",
    name: "ListChoice",
    component: Index,
  },
  {
    path: "/owner/subcategories/choice/:from?",
    name: "OwnerSubCategoriesChoice",
    component: OwnerSubCategoriesChoice,
  },
  {
    path: "/owner/product",
    name: "OwnerProduct",
    component: OwnerProduct,
  },
  {
    path: "/owner/productform/:item?",
    name: "OwnerProductForm",
    component: OwnerProductForm,
  },
  {
    path: "/owner/:item?/attachment",
    name: "AttachmentForm",
    component: AttachmentVue,
  },
  {
    path: "/owner/service",
    name: "OwnerService",
    component: OwnerService,
  },
  {
    path: "/owner/serviceform/:item?",
    name: "OwnerServiceForm",
    component: OwnerServiceForm,
  },
  {
    path: "/owner/addon",
    name: "OwnerAddOn",
    component: OwnerAddOn,
  },
  {
    path: "/owner/subcategories/:from?",
    name: "OwnerSubCategories",
    component: OwnerSubCategories,
  },
  {
    path: "/owner/promo",
    name: "OwnerPromo",
    component: OwnerPromo,
  },
  {
    path: "/owner/promoform/:promo?",
    name: "OwnerPromoForm",
    component: OwnerPromoForm,
  },
  {
    path: "/owner/follower",
    name: "OwnerFollower",
    component: OwnerFollower,
  },
  // lainnya
  {
    path: "/owner/transaction",
    name: "OwnerTransaction",
    component: OwnerTransaction,
  },
  {
    path: "/owner/transaction/:id",
    name: "OwnerDetailTransaction",
    component: OwnerDetailTransaction,
  },
  {
    path: "/owner/employees",
    name: "OwnerUser",
    component: OwnerUser,
  },
  {
    path: "/owner/schedule",
    name: "OwnerSchedule",
    component: OwnerSchedule,
  },
  {
    path: "/owner/subscription",
    name: "OwnerSubscription",
    component: OwnerSubscription,
  },
  {
    path: "/owner/history",
    name: "OwnerHistory",
    component: OwnerHistory,
  },
  {
    path: "/owner/owners",
    name: "OwnerAddOwner",
    component: OwnerOwners,
  },
  {
    path: "/owner/income",
    name: "OwnerIncome",
    component: OwnerIncome,
  },
  {
    path: "/owner/resto",
    name: "OwnerRestoDetail",
    component: OwnerRestoDetail,
  },
  {
    path: "/owner/table/:item?",
    name: "OwnerTable",
    component: OwnerTable,
  },
  {
    path: "/owner/voucher",
    name: "OwnerVoucher",
    component: OwnerVoucher,
  },
];

routes = routes.concat(routesOwner);
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
